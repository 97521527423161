.bmi-head{
    display: flex;
    gap: 3rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    font-style: italic;
    text-transform: uppercase;
}

.bmi-center{
    margin-top: 2rem;
    text-align: center;
    justify-content: center;
    display: flex;
    flex: 1 1;
    gap: 10rem;
    justify-content: center;
}

.bmi-form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
}

.fBtn{
    display: flex;
    
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.fBtn>:nth-child(1){
    background-color: var(--orange);
    color: white;
    padding: 0.5rem;
    font-weight: bold;
    border: 4px solid transparent;
    transition: 300ms all;
}

.fBtn>:nth-child(2){
    background-color: transparent;
    border: 2px solid var(--orange);
    color: white;
    padding: 0.6rem;
}

.bmi-form>div>label{
    color: white;
}

.bmi-form>div>input{
    background-color: rgba(255, 166, 0, 0.423);
    outline: none;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 0.5rem;
}

.bmi-r{
    display: flex;
    flex-direction: column;
    color: white;
    /* border: 2px solid var(--orange); */
    padding: 1.5rem;
    border-radius: 15px;
    background: var(--planCard);
}

.bmi-blur{
    width: 25rem;
    height: 30rem;
    right: 0;
}

@media screen and (max-width: 768px) {
    .bmi-head{
        flex-direction: column;
        gap: 1rem;
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }

    .bmi-center{
        flex-direction: column;
        gap: 1.5rem;
    }

    .bmi-r{
        transform: scale(0.9);
    }

    .blur{
        filter: blur(350px);
    }

    .hero-blur{
        width: 0rem;
        z-index: -9;
    }

    .bmi-blur{
        width: 0rem;
        z-index: -99;
    }
}